import { VisIcon } from '@zeiss/ng-vis-common/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const ROUTE_PATHS = {
  _: 'gssdesigner',
  selectSupplement: 'select-supplement',
  selectTemplate: 'select-template',
  designer: 'designer',
  htmlViewer: 'html-viewer'
};
const ROUTE_CONFIG = {
  path: ROUTE_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'vp.gssdesigner.title',
    description: 'vp.gssdesigner.desc',
    icon: VisIcon.SITE_CREATION,
    showInMenu: false,
    rule: vpRule({
      rule: RULE.access_gss,
      required: ['can_view_designer']
    }),
    routes: [{
      path: '',
      pathMatch: 'full',
      canActivate: [AUTH_GUARD],
      data: {
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_designer']
        })
      }
    }, {
      path: ROUTE_PATHS.selectSupplement,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.gssdesigner.routes.selectSupplement.title',
        description: 'vp.gssdesigner.routes.selectSupplement.desc',
        icon: VisIcon.FILE_REPORT_OVERVIEW,
        showInMenu: true,
        cache: true,
        isAdmin: true,
        hierarchy: 10,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_designer']
        })
      }
    }, {
      path: ROUTE_PATHS.selectTemplate,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.gssdesigner.routes.selectTemplate.title',
        description: 'vp.gssdesigner.routes.selectTemplate.desc',
        icon: VisIcon.EDIT_COPY,
        showInMenu: true,
        cache: true,
        isAdmin: true,
        hierarchy: 11,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_designer']
        })
      }
    }, {
      path: ROUTE_PATHS.designer,
      canActivate: [AUTH_GUARD],
      data: {
        showInMenu: false,
        cache: false,
        hierarchy: 12,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_designer']
        })
      }
    }, {
      path: ROUTE_PATHS.htmlViewer,
      canActivate: [AUTH_GUARD],
      data: {
        showInMenu: false,
        cache: false,
        hierarchy: 13,
        rule: vpRule({
          rule: RULE.access_gss,
          required: ['can_view_designer']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { ROUTE_CONFIG, ROUTE_PATHS };
